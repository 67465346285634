import React from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { Link } from "gatsby"
import loadable from "@loadable/component"

import { PageLinks } from "gatsby-theme-starberry-lomondgroup/src/common/page-links";
import { GetMenu } from "gatsby-theme-starberry-lomondgroup/src/common/queries/common_use_query";

import "gatsby-theme-starberry-lomondgroup/src/components/BranchBanner/BranchBanner.scss";
import ImageModule from "gatsby-theme-starberry-lomondgroup/src/modules/image_module";
import ContentModule from "gatsby-theme-starberry-lomondgroup/src/modules/content_module";

const ReviewRatings = loadable(() =>
    import("gatsby-theme-starberry-lomondgroup/src/modules/review_rating_module")
)

const Branch = ({office_data, location, h1_title}) => {

    const ImageRenderList = ({item, imagename}) => {
        let processedImages = JSON.stringify({});
        if (item?.imagetransforms?.image_Transforms) {
            processedImages = item?.imagetransforms?.image_Transforms;
        }
        return (
          <ImageModule ImageSrc={item.image} title={item.name} altText={item.name} imagetransforms={processedImages} ggfx_results={item?.ggfx_results} renderer="srcSet" imagename={imagename} strapi_id={item.id} />                           
        )
    }

    let split_location = location.pathname.split('/');
    const { data } = GetMenu(split_location[1]);

    //console.log("data_menus ==>", data?.menus, split_location)

    var imagename = "office.image.bg_image";

    let processedImages = JSON.stringify({});
    if (office_data?.imagetransforms?.bg_image) {
        processedImages = office_data?.imagetransforms?.bg_image;
    }

    return (
        <React.Fragment>           

            <section className="branch-banner">
                <div className="breadcrumb-section">
                    <Container>
                        <Row>
                            <Col>
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><Link to={"/"} role="button" tabindex="0">Home</Link></li>
                                {data?.menus?.length > 0 && 
                                    data?.menus[0]?.slug !== PageLinks.branch_finder && (
                                    <li class="breadcrumb-item">
                                        {data?.menus[0]?.page ?
                                            <Link to={`/${split_location[1]}/`} role="button" tabindex="0">{data?.menus[0]?.label}</Link>
                                        :
                                            <a href="javascript:;">{data?.menus[0]?.label}</a>
                                        }
                                    </li>
                                    )
                                }
                                {
                                    <li class="breadcrumb-item"><Link to={`/${PageLinks.branch_finder}/`} role="button" tabindex="0">Branch Finder</Link></li>
                                }
                                <li class="breadcrumb-item last-item"><a href="javascript:;">{office_data.name}</a></li>
                            </ol>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {/* <span className="bg_img" style={{backgroundImage:`url(${office_data.image.url})`}}></span> */}

                {office_data.image && <ImageModule ImageSrc={office_data.image} altText={office_data?.name} imagetransforms={processedImages} renderer="bgImg" imagename={imagename} strapi_id={office_data?.id} classNames="bg_img" />}

                <span className="transp_bg"></span>
                <div className="branch-img">
                    <ImageRenderList item={office_data} imagename={"office.image.details"} />
                </div>
                <Container>
                    <Row>
                        <Col>
                            <div className="branch-wrapper">
                                
                                <div className="content-wrap">
                                    <div className="branch-content">
                                        <h1>{h1_title}</h1>
                                        {office_data?.intro_section?.text_block?.description && <div className="branch-content-wrap"><ContentModule Content={office_data?.intro_section?.text_block?.description}/></div> }
                                    </div>
                                    <div className="btn-wrapper d-flex">
                                        <Link to={`/${PageLinks.request_valuation}/`} className="btn btn-primary">Book a valuation</Link>
                                        <Link to={`/${PageLinks.branch_finder}/${office_data.slug}/${PageLinks.branch_contact}`} className="btn btn-outline">Contact us</Link>
                                    </div>
                                </div>
                               
                                <ReviewRatings />
                            </div>
                        </Col>
                    </Row>
                  
                </Container>
            </section>
        </React.Fragment>
    );
};
export default Branch;
